import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import Logo from "../../Assets/Images/Logo-White.png";
import { useNavigate } from "react-router-dom";
import LanguageDropdown from "../../Components/Header/LanguageDropdown";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const Header = ({ t }) => {
  const naviagte = useNavigate();
  const data = [
    {
      text: t("Home"),
      link: "#banner",
    },
    {
      text: t("Service"),
      link: "#services",
    },
    {
      text: t("FAQ"),
      link: "#faq",
    },
    // {
    //   text: t("Buy Token"),
    //   link: "#buy-token",
    // },
    {
      text: t("Contact us"),
      link: "#contact-us",
    },
  ];
  return (
    <div className="home_header_page mt-2">
      <div className="header_content">
        <Navbar collapseOnSelect expand="lg" className="p-0">
          <Navbar.Brand href="/">
            <div className="d-flex align-items-center">
              <img src={Logo} alt="header-logo" height={50} />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="m-auto">
              {data.map((item, i) => (
                <Nav.Link
                  href={item?.link}
                  key={i}
                  className="text-center mx-4"
                >
                  {item?.text}
                </Nav.Link>
              ))}
              <Nav.Link>
                <LanguageDropdown isHeader={true} />
              </Nav.Link>
            </Nav>
            <div className="home_nav_buttons d-flex gap-2 ms-auto">
              <button
                className="btn button_1"
                onClick={() => naviagte("/signup")}
              >
                {t("Sign Up")}
              </button>
              <button
                className="btn button_2"
                onClick={() => naviagte("/login")}
              >
                {t("Log In")}
              </button>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default withTranslation()(Header);

Header.propTypes = {
  t: PropTypes.any,
};
