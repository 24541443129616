import React from "react";
import ContactImg from "../../Assets/Images/contact.png";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const Contact = ({ t }) => {
  return (
    <div className="home_contact_page common-margin p-5" id="contact-us">
      <div className="row">
        <div className="col-xl-8 d-none d-sm-block">
          <h1>{t("Contact us")}</h1>
          <img src={ContactImg} alt="contact" className="d-lg-block d-none" />
        </div>
        <div className="col-xl-4 col-sm-12 px-5">
          <form>
            <div className="form-group mb-3">
              <label>{t("Name")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Enter name")}
              />
            </div>
            <div className="form-group mb-3">
              <label>{t("Surname")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Enter surname")}
              />
            </div>
            <div className="form-group mb-3">
              <label>{t("Email")}</label>
              <input
                type="email"
                className="form-control"
                placeholder={t("Email")}
              />
            </div>
            <div className="form-group mb-4">
              <label>{t("Message")}</label>
              <textarea
                type="text"
                className="form-control"
                rows={5}
                placeholder={t("Message")}
              />
            </div>

            <button type="submit" className="btn w-100">
              {t("Send")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Contact);

Contact.propTypes = {
  t: PropTypes.any,
};
