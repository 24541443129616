import React from "react";
import Logo from "../../Assets/Images/Logo-White.png";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const Footer = ({ t }) => {
  return (
    <div className="home_footer_page px-5">
      <div className="footer_link_content m-auto py-5">
        <div className="row">
          <div className="col-xl-4 col-sm-12">
            <img src={Logo} alt="header-logo" className="img-fluid" />
          </div>
          <div className="col-xl-4 col-sm-12 d-flex justify-content-center text-xl-start text-center">
            <ul>
              <h5>{t("Company Terms")}</h5>
              {/* <li style={{ cursor: "pointer" }}>
                <a href="/about-us" target="_blank" style={{ color: "#fff" }}>
                  {t("About")}
                </a>
              </li> */}
              <li style={{ cursor: "pointer" }}>
                <a
                  href="/terms-conditions"
                  target="_blank"
                  style={{ color: "#fff" }}
                >
                  {t("Terms & conditions")}
                </a>
              </li>
              <li style={{ cursor: "pointer" }}>
                <a
                  href="/privacy-policy"
                  target="_blank"
                  style={{ color: "#fff" }}
                >
                  {t("Privacy Policy")}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-xl-4 col-sm-12 d-flex justify-content-center text-xl-start text-center">
            <ul>
              <h5>{t("Contact Us")}</h5>
              <li>Avenida de Los Shyris y Suecia, Quito - Ecuador </li>
              <li>+593 989116342 </li>
              <li>info@fintechblok.com</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="pb-2">
        <hr style={{ opacity: "1" }} />
        <p>
          {t("Copyright © 2024. Designed & Developed by")}{" "}
          <a
            style={{ color: "#ffffff" }}
            href="https://blocktechbrew.com/"
            target="_blank"
            rel="noreferrer"
            className="fw-bold"
          >
            Block Tech Brew
          </a>
        </p>
      </div>
    </div>
  );
};

export default withTranslation()(Footer);

Footer.propTypes = {
  t: PropTypes.any,
};
