import React from "react";
import Header from "./header";
import Banner from "./banner";
import Features from "./features";
import Services from "./services";
import BuyTokens from "./buyTokens";
import Contact from "./contact";
import Faq from "./faq";
import Footer from "./footer";
import "./landingPage.css";

const LandingPage = () => {
  return (
    <>
      <div className="px-2">
        <Header />
        <Banner />
      </div>
      <Features />
      <Services />
      {/* <BuyTokens /> */}
      <Contact />
      <Faq />
      <Footer />
    </>
  );
};

export default LandingPage;
