import React from "react";
import ServicesImg from "../../Assets/Images/services.png";
import Service1 from "../../Assets/Images/service_1.png";
import Service2 from "../../Assets/Images/service_2.png";
import Service3 from "../../Assets/Images/service_3.png";
import Service4 from "../../Assets/Images/service_4.png";
import Service5 from "../../Assets/Images/service_5.png";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const Services = ({ t }) => {
  return (
    <div className="home_services_page common-margin pt-4" id="services">
      <div className="container">
        <h2>{t("Our Services")}</h2>
        <div className="servces_content mx-5">
          <div className="row">
            <div className="col-xl-3 col-sm-12">
              <div className="service_content_container">
                <img src={Service1} alt="service" />
                <h6 className="mt-2">{t("Programmed Savings Service")}</h6>
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit</p>
              </div>
              <div
                className="service_content_container"
                style={{ marginTop: "100px" }}
              >
                <img src={Service2} alt="service" />
                <h6 className="mt-2">{t("Remittance")}</h6>
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit</p>
              </div>
            </div>
            <div className="col-xl-6 col-sm-12 text-center">
              <img
                src={ServicesImg}
                alt="services"
                className="mt-5 d-none d-lg-block img-fluid"
              />
              <div className="row">
                <div className="col-xl-6 col-sm-12 offset-lg-3">
                  <div className="service_content_container mt-3">
                    <img src={Service3} alt="service" />
                    <h6 className="mt-2">{t("Traditional Banking")}</h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-12">
              <div className="service_content_container">
                <img src={Service4} alt="service" />
                <h6 className="mt-2">{t("Crowd funding Entrepreneurs")}</h6>
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit</p>
              </div>
              <div
                className="service_content_container"
                style={{ marginTop: "100px" }}
              >
                <img src={Service5} alt="service" />
                <h6 className="mt-2">{t("Crowd funding- Investor")}</h6>
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Services);

Services.propTypes = {
  t: PropTypes.any,
};
