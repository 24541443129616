import React, { useContext, useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { get, map } from "lodash";
import { withTranslation } from "react-i18next";
import usFlag from "../../Assets/Images/us.jpg";
import spainFlag from "../../Assets/Images/spain.jpg";

//i18n
import i18n from "../../i18n";
import languages from "../Common/languages";
import { UserContext } from "../../context";

const LanguageDropdown = ({ isHeader }) => {
  const { setLanguage } = useContext(UserContext);
  const [selectedLang, setSelectedLang] = useState("");
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
    setSelectedLang(currentLanguage);
  }, []);

  const changeLanguageAction = (lang) => {
    //set language as i18n
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    setLanguage(lang);
    setSelectedLang(lang);
  };

  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="btn header-item " tag="button">
          <div className="d-flex align-items-center justify-content-center">
            <img
              src={selectedLang === "sp" ? spainFlag : usFlag}
              alt="flags"
              height="16"
              className="me-1"
            />
            <span
              style={{
                color: isHeader && "#ffffff",
              }}
            >
              {selectedLang === "sp" ? "español" : "English"}
            </span>
          </div>
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end">
          {map(Object.keys(languages), (key) => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${
                selectedLang === key ? "active" : "none"
              }`}
            >
              <img
                src={get(languages, `${key}.flag`)}
                alt="Skote"
                className="me-1"
                height="12"
              />
              <span className="align-middle">
                {get(languages, `${key}.label`)}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(LanguageDropdown);
