import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Loginpage from "./Components/Auth/Loginpage";
import UserKyc from "./Components/Auth/UserKyc";
import Purpose from "./Components/Purpose/Purpose";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CorporateLayout from "./Components/CorporateLayout/Layout";
import LandingPage from "./Pages/LandingPage";
import { authProtectedLayoutRoutes, publicRoutes } from "../src/Routes";
import "react-toastify/dist/ReactToastify.css";
// import "bootstrap-daterangepicker/daterangepicker.css";
import InvestTC from "./Pages/InvestTC";
import Privacy from "./Pages/Privacy";
import Terms from "./Pages/Terms";
import About from "./Pages/About";
import PrivateRoutes from "./Routes/privateRoutes";
import PublicRoutes from "./Routes/publicRoutes";
import KycProtectRoutes from "./Routes/kycProtectRoute";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<KycProtectRoutes />}>
          <Route path="/user-kyc" element={<UserKyc />} />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route path="/select-purpose" element={<Purpose />} />
          <Route path="/service-t&c" element={<InvestTC />} />

          <Route element={<CorporateLayout />}>
            {authProtectedLayoutRoutes?.map((item, i) => (
              <Route key={i} path={item.path} element={<item.component />} />
            ))}
          </Route>
        </Route>
        <Route element={<Privacy />} path="/privacy-policy" />
        <Route element={<Terms />} path="/terms-conditions" />
        {/* <Route element={<About />} path="/about-us" /> */}

        <Route element={<PublicRoutes />}>
          <Route element={<Loginpage />} path="/login" />
          <Route element={<LandingPage />} path="/home" />
          {publicRoutes?.map((item, i) => (
            <Route key={i} path={item.path} element={<item.component />} />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
